import React from 'react';
import { Typography } from 'antd';

import AppLayout from 'components/AppLayout'

const { Title } = Typography;

const FourOhFour = () => {
  return (
    <AppLayout>
      <Title className="text-center" level={1}>Page not found</Title>
    </AppLayout>
  )
};

export default FourOhFour;
