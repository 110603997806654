import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface ISpinner {
  loading?: boolean
}

const StyledSpinnerWrapper = styled.div`
  .ant-spin {
    &.page-spinner {
      min-height: 100vh;
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .ant-spin-text {
        padding-top: 12px;
      }
    }
  }
`;

export const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

const Spinner: React.FC<ISpinner> = ({ loading = true }) => {
  return (
    <StyledSpinnerWrapper>
      <Spin
        className="page-spinner"
        size="large"
        indicator={LoadingIcon}
        spinning={loading}
      />
    </StyledSpinnerWrapper>
  );
};

export default Spinner;
