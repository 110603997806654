const intercomInit = () => {
  (window as any).intercomSettings = {
    app_id: "udipzsuy"
  };
  (function() {
    var w = window as any;
    var ic = w.Intercom as any;
    if (typeof ic === "function") {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document as Document;
      var i = function() {
        i.c(arguments);
      } as any;
      i.q = [];
      i.c = function(args: any) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/udipzsuy';
        var x: HTMLScriptElement = d.getElementsByTagName('script')[0];
        x?.parentNode?.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
}

const inspectletInit = () => {
  (function() {
    (window as any).__insp = (window as any).__insp || [];
    (window as any).__insp.push(['wid', 1154828947]);
    var ldinsp = function() {
      if (typeof (window as any).__inspld != "undefined") return;
      (window as any).__inspld = 1;
      var insp = document.createElement('script');
      insp.type = 'text/javascript';
      insp.async = true;
      insp.id = "inspsync";
      insp.src = ('https:' === document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=1154828947&r=' + Math.floor(new Date().getTime() / 3600000);
      var x: any = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(insp, x);
    };
    setTimeout(ldinsp, 0);
  })();
}

const fbpixelInit = () => {
  (function(f: any, b: Document, e: string, v: string, n?: any, t?: any, s?: any) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  (window as any).fbq('init', '1502726613219483');
  (window as any).fbq('track', 'PageView');
}

export default () => {
  fbpixelInit();
  intercomInit();
  inspectletInit();
};
