import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import 'antd/dist/antd.css';
import 'bootstrap-utilities/bootstrap-utilities.css';
import 'stylesheets/typography.css';
import 'stylesheets/background.css';
import 'stylesheets/container.css';
import 'stylesheets/button.css';

import App from './App';

const rootElement: HTMLElement | null = document.getElementById('root');
const renderMethod = rootElement?.hasChildNodes() ? hydrate : render;

renderMethod(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
