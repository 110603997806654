import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import FourOhFour from 'pages/FourOhFour';
import Spinner from 'components/Spinner';
import routes from './config/routes';
import integrations from 'config/integrations';

function App() {

  useEffect(() => {
    if (navigator.userAgent !== 'ReactSnap') integrations();    
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {routes.map(({ component: Component, path }) => (
          <Route
            key={path}
            path={path}
            render={() => <Component />}
          />
        ))}
        <Redirect exact from="/" to="/signup"/>
        <Route component={FourOhFour} />
      </Switch>
    </Suspense>
  );
}

export default App;
